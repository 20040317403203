import React, { useCallback, useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import styles from './FullscreenContainer.module.scss';
import { OverlayFrame } from './OverlayFrame';

export const FullscreenContainer = (props: {
  children: (opened: boolean) => React.ReactNode,
  className?: string,
  onClose: () => void,
  bottom?: React.ReactNode
}) => {
  const { children, className, onClose, bottom } = props;

  useEffect(() => {
    const onKey = (event: KeyboardEvent) => {
      if (event.key == 'Escape')
        onClose();
    };

    window.addEventListener('keydown', onKey);

    return () => window.removeEventListener('keydown', onKey);
  }, [onClose]);

  const [contentContainerSize, setContainerSize] = useState({ width: undefined, height: undefined });

  const onResize = useCallback((width: number, height: number) => {
    const size = { width, height: height - 84 };

    let h = size.width * 9 / 16;
    let w = size.height * 16 / 9;

    if (w < width)
      h = size.height;
    else
      w = size.width;

    setContainerSize({ width: w, height: h });
  }, []);

  return (
    <ReactResizeDetector onResize={onResize}>
      <OverlayFrame
        className={className}
        onClick={onClose}
      >
        {(opened) => (
          <div className={styles.container}>
            <div className={styles.top}>
              <button className={styles.closeButton} onClick={onClose} />
            </div>
            <div
              className={styles.contentContainer}
              style={{ width: contentContainerSize.width, height: contentContainerSize.height }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={styles.contentLoading}>Loading...</div>
              <div className={styles.content}>
                {children(opened)}
              </div>
            </div>
            <div className={styles.bottom}>
              {bottom}
            </div>
          </div>
        )}
      </OverlayFrame>
    </ReactResizeDetector>
  );
}
