import React from 'react';

export const YouTubeVideoPlayer = (props: { videoId: string, width: number | string, height: number | string }) => {
  const { videoId, width, height } = props;

  return (
    <iframe
      id='ytplayer'
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${videoId}?autoplay=1&modestbranding=1&iv_load_policy=3&rel=0`}
      frameBorder='0'
      allowFullScreen={true}
    ></iframe>
  );
}
