import classnames from 'classnames';
import { graphql } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import logoDrigtingLands from '../assets/logo-driftinglands.svg';
import logoForetales from '../assets/logo-foretales.svg';
import logoTranscripted from '../assets/logo-transcripted.svg';
import studioMember01 from '../assets/studio-member-01.png';
import studioMember02 from '../assets/studio-member-02.png';
import studioMember03 from '../assets/studio-member-03.png';
import studioMember04 from '../assets/studio-member-04.png';
import studioMember05 from '../assets/studio-member-05.png';
import { Anchor } from '../components/Anchor';
import { AnchorLink, useScrollOnHistory } from '../components/AnchorLink';
import { FullscreenContainer } from '../components/FullscreenContainer';
import { Gallery, IGalleryItem } from "../components/Gallery";
import { TiltedSeparator } from '../components/TiltedSeparator';
import { VerticalExpander } from '../components/VerticalExpander';
// import { Wip } from '../components/Wip';
import { YouTubeVideoPlayer } from '../components/YouTubeVideoPlayer';
import * as styles from './index.module.scss';

export const query = graphql`
  query {
    currentBuildDate {
      currentDate
    }
  }
`;

export default function Home(props: { data: { currentBuildDate: { currentDate: string } } }) {
  const { data } = props;

  useEffect(() => {
    console.log(`Build: ${data.currentBuildDate.currentDate}`);
  }, []);

  // return <Wip />;

  useScrollOnHistory();

  const [displayShowreel, setDisplayShowreel] = useState(false);

  const playShowreel = useCallback(() => setDisplayShowreel(true), []);
  const closeShowreel = useCallback(() => setDisplayShowreel(false), []);

  return (
    <div className={styles.main}>

      <Header home={true} />
      <Showreel playShowreel={playShowreel} />
      <TiltedSeparator angle={1} mainColor='white' rightColor='#922e46' />
      <Studio />
      <TiltedSeparator angle={-2.5} leftColor='white' mainColor='#ff3364' rightColor='#ff3364' />
      <Games />
      <TiltedSeparator angle={2} mainColor='white' rightColor='#ff3364' />
      <Services />
      <Footer />

      {
        displayShowreel && (
          <FullscreenContainer
            onClose={closeShowreel}
          >
            {(opened) => (
              opened && <YouTubeVideoPlayer videoId='wDwXQ-1bBoI' width='100%' height='100%' />
            )}
          </FullscreenContainer>
        )
      }

    </div>
  )
}

export const Header = (props: { home: boolean }) => {
  const { home } = props;

  const sections = ['showreel', 'studio', 'games', 'services'];
  const [activeSection, setActiveSection] = useState<string>();

  useEffect(() => {
    if (!home) return;

    const onScroll = () => {
      const result = sections.map((section) => {
        const rect = document.getElementsByClassName(section)[0].getBoundingClientRect();
        const distance = Math.min(Math.abs(rect.top - 150), Math.abs(rect.bottom - 151));
        return { section, distance };
      }).sort((a, b) => a.distance < b.distance ? -1 : 1);

      setActiveSection(result[0].section);
    };

    onScroll();

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [home]);

  const onEmailClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();

    const details = {
      mail: 'mail',
      to: 'to',
      contact: 'contact',
      domain: 'alkemi-games.com'
    };

    window.open(details.mail + details.to + ':' + details.contact + '@' + details.domain);
  }, []);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerBackgroundContainer}>
          <div className={styles.containerContent}>
            <div className={styles.headerBackground} />
          </div>
        </div>
        <div className={classnames(styles.containerContent, styles.headerContent)}>
          <div className={styles.headerLogo}>
            <AnchorLink scroll={home} href='/#showreel' behavior='smooth' />
          </div>
          <div className={styles.headerNav}>
            <AnchorLink
              scroll={home}
              href='/#studio'
              behavior='smooth'
              className={activeSection === 'studio' ? styles.active : undefined}
            >
              Studio
            </AnchorLink>
            <AnchorLink
              scroll={home}
              href='/#games'
              behavior='smooth'
              className={activeSection === 'games' ? styles.active : undefined}
            >
              Games
            </AnchorLink>
            <AnchorLink
              scroll={home}
              href='/#services'
              behavior='smooth'
              className={activeSection === 'services' ? styles.active : undefined}
            >
              Services
            </AnchorLink>
          </div>
          <div className={styles.headerContact}>
            <a className={styles.topEmail} href="#" onClick={onEmailClick}></a>
            <a
              className={styles.topTwitter}
              href='https://twitter.com/alkemigames'
              target='_blank'
              rel='noopener noreferrer'
            >
            </a>
          </div>
        </div>
      </div>
      {
        home &&
        <div className={styles.underHeader} />
      }
    </>
  );
}

const Showreel = (props: { playShowreel: () => void }) => {
  const { playShowreel } = props;

  return (
    <section className='showreel'>
      <Anchor id='showreel' offset={-84} />
      <div className={styles.showreel}>
        <div className={styles.containerContent}>
          <div className={styles.container169}>
            <div className={styles.showreelVideo}>
              <video preload='auto' autoPlay={true} muted={true} playsInline={true} loop={true}>
                <source src='showreel-teaser.webm' type='video/webm' />
                <source src='showreel-teaser.mp4' type='video/mp4' />
              </video>
              <div className={styles.showreelCenter} onClick={playShowreel}>
                <div className={styles.showreelPlayIcon} />
                <div className={styles.showReelText}>Play our Showreel!</div>
              </div>
              <div className={styles.showReelChips}>
                <div className={styles.showReelChip1} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const Studio = () => {
  return (
    <section className='studio'>
      <Anchor id='studio' />
      <div className={classnames(styles.containerContent, styles.studioContent)}>
        <div className={styles.sectionTitle}>
          The Studio
        </div>
        <div className={styles.sectionText}>
          Alkemi is more than 10 years old now. We’re a small team of craft people
          making our own games and other visual stuff but mostly in the game industry.
          We make medium sized games, polished games, sometimes weird games and we
          focus on making stuff that you’ve probably never played before. We also
          work for others, bringing our technical and artistic expertise to create
          solutions, assets & tools.
        </div>
        <div className={styles.studioMembers}>
          <div className={styles.studioMember}>
            <img src={studioMember01} />
            Alain Puget
          </div>
          <div className={styles.studioMember}>
            <img src={studioMember02} />
            Christophe Zerr
          </div>
          <div className={styles.studioMember}>
            <img src={studioMember03} />
            Pierre-Vincent Cabourg
          </div>
          <div className={styles.studioMember}>
            <img src={studioMember04} />
            Kevin Struillou
          </div>
          <div className={styles.studioMember}>
            <img src={studioMember05} />
            Anaïs Lehoux
          </div>
        </div>
      </div>
    </section>
  );
}

const Games = () => {
  return (
    <section className='games'>
      <Anchor id='games' />
      <div className={styles.games}>
        <div className={classnames(styles.containerContent, styles.gamesContent)}>
          <div className={styles.sectionTitle}>
            Our Games
          </div>
          <Game
            logo={logoForetales}
            text={
              <>
                Foretales is a new kind of card game where narration and
                experimentation blend with deck mechanics. It’s currently in
                development and it will be awesome.
                <div className={styles.gamesLinks}>
                  <a
                    className={styles.gamesForetalesTwitter}
                    href='https://twitter.com/foretales_game'
                    target='_blank'
                    rel='noopener noreferrer'
                  />
                </div>
              </>
            }
            galleryItems={[
              {
                thumbnail: 'games-foretales-00-thumb.jpg',
                youTubeVideo: {
                  cover: 'games-foretales-00-cover.jpg',
                  videoId: 'jaMqr33Wbhs'
                },
              },
              {
                thumbnail: 'games-foretales-01-thumb.jpg',
                image: 'games-foretales-01.jpg',
              },
              {
                thumbnail: 'games-foretales-02-thumb.jpg',
                image: 'games-foretales-02.jpg',
              },
              {
                thumbnail: 'games-foretales-03-thumb.jpg',
                image: 'games-foretales-03.jpg',
              },
              {
                thumbnail: 'games-foretales-04-thumb.jpg',
                image: 'games-foretales-04.jpg',
              },
            ]}
          />
          <LineSeparator color='#fd9aae' />
          <Game
            logo={logoDrigtingLands}
            text={
              <>
                What would happen if you ripped out the mindless mouse clicking
                of a modern Diablo-like and replaced it with an old school shootemup
                gameplay? If you keep the loot, the choice of skills and powers, the
                customization of your equipment, Drifting Lands will happen.
                <div className={styles.gamesLinks}>
                  <a
                    className={styles.gamesLogoSteam}
                    href='https://store.steampowered.com/app/322750/Drifting_Lands/'
                    target='_blank'
                    rel='noopener noreferrer'
                  />
                </div>
              </>
            }
            galleryItems={[
              {
                thumbnail: 'games-driftinglands-00-thumb.jpg',
                youTubeVideo: {
                  cover: 'games-driftinglands-00-cover.jpg',
                  videoId: 'QoLxZCYA_t4'
                },
              },
              {
                thumbnail: 'games-driftinglands-01-thumb.jpg',
                image: 'games-driftinglands-01.jpg',
              },
              {
                thumbnail: 'games-driftinglands-02-thumb.jpg',
                image: 'games-driftinglands-02.jpg',
              },
              {
                thumbnail: 'games-driftinglands-03-thumb.jpg',
                image: 'games-driftinglands-03.jpg',
              },
              {
                thumbnail: 'games-driftinglands-04-thumb.jpg',
                image: 'games-driftinglands-04.jpg',
              },
            ]}
          />
          <LineSeparator color='#fd9aae' />
          <Game
            logo={logoTranscripted}
            text={
              <>
                Imagine a mix of Twin stick shooters with a match-3 game… Weird idea
                right? The crazy thing is that it actually works and will bend your
                brain in a few new and satisfying ways!
                <div className={styles.gamesLinks}>
                  <a
                    className={styles.gamesLogoSteam}
                    href='https://store.steampowered.com/app/215450/Transcripted/'
                    target='_blank'
                    rel='noopener noreferrer'
                  />
                  <a
                    className={styles.gamesLogoSwitch}
                    href='https://www.nintendo.com/games/detail/transcripted-switch/'
                    target='_blank'
                    rel='noopener noreferrer'
                  />
                  <a
                    className={styles.gamesLogoPs4}
                    href='https://store.playstation.com/en-gb/product/EP1302-CUSA06252_00-TRANSCRIPTED0000'
                    target='_blank'
                    rel='noopener noreferrer'
                  />
                  <a
                    className={styles.gamesLogoXBox}
                    href='https://www.microsoft.com/en-gb/p/transcripted/bxkfl4q6wxvp'
                    target='_blank'
                    rel='noopener noreferrer'
                  />
                </div>
              </>
            }
            galleryItems={[
              {
                thumbnail: 'games-transcripted-00-thumb.jpg',
                youTubeVideo: {
                  cover: 'games-transcripted-00-cover.jpg',
                  videoId: 'qXDiO8oCavM'
                },
              },
              {
                thumbnail: 'games-transcripted-01-thumb.jpg',
                image: 'games-transcripted-01.jpg',
              },
              {
                thumbnail: 'games-transcripted-02-thumb.jpg',
                image: 'games-transcripted-02.jpg',
              },
              {
                thumbnail: 'games-transcripted-03-thumb.jpg',
                image: 'games-transcripted-03.jpg',
              },
              {
                thumbnail: 'games-transcripted-04-thumb.jpg',
                image: 'games-transcripted-04.jpg',
              },
            ]}
          />
        </div>
      </div>
    </section>
  );
}

const Game = (props: { logo: any, text: React.ReactNode, galleryItems: IGalleryItem[] }) => {
  const { logo, text, galleryItems } = props;

  const [expanded, setExpanded] = useState(false);
  const expand = useCallback(() => setExpanded(true), []);

  return (
    <div>
      <div className={styles.gamesGameTop}>
        <div className={styles.gamesGameTopLogo}>
          <img src={logo} />
        </div>
        <div className={styles.gamesGameTopText}>
          {text}
        </div>
      </div>
      <VerticalExpander expanded={!expanded}>
        <button
          className={classnames(styles.gamesGameMore, { [styles.remove]: expanded })}
          onClick={expand}
        />
      </VerticalExpander>
      <VerticalExpander expanded={expanded}>
        <Gallery
          items={galleryItems}
        />
      </VerticalExpander>
    </div>
  );
}

const Services = () => {
  return (
    <section className='services'>
      <Anchor id='services' />
      <div className={classnames(styles.containerContent, styles.servicesContent)}>
        <div className={styles.sectionTitle}>
          Our Services
        </div>
        <Service
          videoSrcs={[
            { src: 'services-tools-dev.webm', type: 'video/webm' },
            { src: 'services-tools-dev.mp4', type: 'video/mp4' },
          ]}
          title={
            <>
              Tools & Dev Support
            </>
          }
          text={
            <>
              To manage large amounts of game data or assets, we make our own
              tools: sometimes in Unity for asset management, but mostly web
              based tools to create multi-users collaborative editors synchronized
              with the game. If you’re struggling to create friendly environments
              for narrative design, game balancing, localization… maybe we can help.
            </>
          }
        />
        <LineSeparator color='#ffc1d2' />
        <Service
          videoSrcs={[
            { src: 'services-vfx-techart.webm', type: 'video/webm' },
            { src: 'services-vfx-techart.mp4', type: 'video/mp4' },
          ]}
          title={
            <>
              VFX & Tech Art
            </>
          }
          text={
            <>
              Our favorite challenge? “How to make the final game look like the
              original amazing concept arts”. We bend shaders and rendering to
              create original, cool looking and consistent art styles for our
              games or yours.

              We’re more or less all technical artists here, so we do a lot of
              Fizzzzzzz, BOOM, KzzKzzzz... always with minimum processing power
              and texture size.
            </>
          }
        />
        <LineSeparator color='#ffc1d2' />
        <Service
          videoSrcs={[
            { src: 'services-ads.webm', type: 'video/webm' },
            { src: 'services-ads.mp4', type: 'video/mp4' },
          ]}
          title={
            <>
              Trailers & Promo Art
            </>
          }
          text={
            <p style={{ margin: 0 }}>
              We’ve been working in the digital marketing industry for more than
              a decade now. Doing trailers, video ads, banners, website skins for
              small indie games and huge AAA games. We know how to make cool visual
              stuff for all kinds of budgets whatever the starting material may be.
              For years, we’ve been doing most of those cool things with our friends
              at <a href='https://biborg.com' target='_blank' rel='noopener noreferrer'>Biborg</a>, one of the
              top creative agencies for the gaming industry.
            </p>
          }
        />
      </div>
    </section>
  );
}

const Service = (props: { videoSrcs: { src: string, type: string }[], title: React.ReactNode, text: React.ReactNode }) => {
  const { videoSrcs, title, text } = props;

  return (
    <div>
      <div className={styles.servicesServiceTop}>
        <div className={styles.servicesServiceTopVideo}>
          <video preload='auto' autoPlay={true} muted={true} playsInline={true} loop={true}>
            {
              videoSrcs.map(src => (
                <source key={src.src} src={src.src} type={src.type} />
              ))
            }
          </video>
        </div>
        <div className={styles.servicesServiceTopText}>
          <div className={styles.servicesServiceTopTitle}>
            {title}
          </div>
          {text}
        </div>
      </div>
    </div>
  );
}

const Footer = () => {
  return (
    <section className='footer'>
      <div className={styles.containerContent}>
        <div className={styles.footerContent}>
          Alkemi - 2020
        </div>
      </div>
    </section>
  );
}

const LineSeparator = (props: { color: string }) => {
  const { color } = props;

  return (
    <div className={styles.lineSeparator} style={{ backgroundColor: color }} />
  );
}

