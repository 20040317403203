import React from 'react';

export const Anchor = (props: { id: string, offset?: number }) => {
  const { id, offset } = props;

  return (
    <div
      id={id}
      style={{ position: 'relative', top: offset ?? -100 }}
    />
  );
}
