import React, { useEffect, useState } from 'react';
import * as styles from './OverlayFrame.module.scss';
import classNames from 'classnames';

export const OverlayFrame = (props: {
  className?: string,
  children: (opened: boolean) => React.ReactNode,
  onClick: (event: React.MouseEvent) => void
}) => {
  const { className, children, onClick} = props;

  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setImmediate(() => setOpen(true));
    setTimeout(() => setOpened(true), 500);
  });

  useEffect(() => {
    const cancel = (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    window.addEventListener('keydown', cancel);
    window.addEventListener('wheel', cancel, { passive: false });
    window.addEventListener('touchmove', cancel, { passive: false });

    return () => {
      window.removeEventListener('keydown', cancel);
      window.removeEventListener('wheel', cancel);
      window.removeEventListener('touchmove', cancel);
    };
  });

  return (
    <div className={styles.overlayFrameContainer}>
      <div
        className={classNames(styles.overlayFrame, { [styles.overlayFrameOpened]: open }, className)}
        onClick={onClick}
      >
        {children(opened)}
      </div>
    </div>
  );
};
