import React, { useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';

export const VerticalExpander = (props: { expanded: boolean, children: React.ReactNode }) => {
  const { expanded, children } = props;

  const [childrenHeight, setChildrenHeight] = useState<number>(undefined);

  const onResize = (_width: number, height: number) => {
    setChildrenHeight(height);
  };

  return (
    <div style={{
      overflow: 'hidden',
      height: expanded ? childrenHeight ?? 'auto' : 0,
      opacity: expanded ? 1 : 0,
      transition: 'height 0.25s ease-out, opacity 0.25s linear',
    }}>
      <ReactResizeDetector handleHeight onResize={onResize}>
        <div style={{
          overflow: 'auto' // NOTE: to include children margins
        }}>
          {children}
        </div>
      </ReactResizeDetector>
    </div>
  );
}
