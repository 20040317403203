import React from 'react';
import * as styles from "../pages/index.module.scss";

const height = 300;

export const TiltedSeparator = (props: {
  topDelta?: number,
  angle: number,
  leftColor?: string,
  mainColor: string,
  rightColor?: string,
}) => {
  const { topDelta, angle, leftColor, mainColor, rightColor } = props;

  return (
    <div style={{
      position: 'relative',
      top: height * -0.25 + (topDelta ?? 0),
      pointerEvents: 'none'
    }}>
      <div style={{
        position: 'absolute',
        width: '100%',
        height: height,
        overflow: 'hidden',
      }}>
        <div className={styles.containerContent} style={{
          position: 'relative',
          top: height * 0.5,
        }}>
          <div style={{
            position: 'absolute',
            right: 0,
            top: height * -0.25,
            width: '100vw',
            height: height * 0.5,
            background: leftColor
          }} />
          <div style={{
            position: 'absolute',
            left: '100%',
            top: height * -0.25,
            width: '50vw',
            height: height * 0.5,
            background: rightColor
          }} />
          <div style={{
            position: 'absolute',
            top: height * -0.25,
            left: '100%',
            width: '200vw',
            height: height * 0.5,
            transformOrigin: '0% 50%',
            transform: `rotate(${angle}deg) translate(-150vw, 0)`,

            background: mainColor
          }} />
        </div>
      </div>
    </div>
  );
}
