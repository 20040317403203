import React, { useCallback, useEffect } from 'react';

export const AnchorLink = (props: {
  className?: string,
  href: string,
  scroll: boolean,
  offset?: number,
  behavior?: 'auto' | 'smooth',
  children?: React.ReactNode,
}) => {
  const { className, scroll, href, offset, behavior, children } = props;

  const handleClick = useCallback((event: React.MouseEvent) => {
    if (!scroll) return;

    event.preventDefault();

    const linkId = href.split('#')[1]

    scrollTo({ linkId, offset, behavior });

    history.pushState(
      { linkId, offset, behavior },
      null,
      href
    );
  }, [scroll, href, offset, behavior]);

  return (
    <a
      className={className}
      href={href}
      onClick={handleClick}
    >
      {children}
    </a>
  );
}

export const useScrollOnHistory = () => {
  useEffect(() => {
    if (!('scrollRestoration' in history)) return;
    history.scrollRestoration = 'manual';

    function onPopstate(event: PopStateEvent) {
      if (event.state?.linkId == null)
        window.scrollTo({ top: 0, behavior: 'smooth' });
      else
        scrollTo(event.state);
    }

    window.addEventListener('popstate', onPopstate);

    return () => {
      window.removeEventListener('popstate', onPopstate);
    };
  }, []);
}

const scrollTo = (opts: { linkId: string, offset?: number, behavior?: 'auto' | 'smooth' }) => {
  const coords = getCoords(document.getElementById(opts.linkId));
  const options = { top: Math.max(1, coords.top + (opts.offset ?? 0)), behavior: opts.behavior };
  window.scrollTo(options);

  // document.getElementById(opts.linkId).scrollIntoView({
  //   behavior: opts.behavior
  // });
}

function getCoords(elem: HTMLElement) { // crossbrowser version
  var box = elem.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

  var top = box.top + scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;
  var bottom = box.bottom + scrollTop - clientTop;
  var right = box.right + scrollLeft - clientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
    bottom: Math.round(bottom),
    right: Math.round(right)
  };
}
