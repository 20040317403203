import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { FullscreenContainer } from './FullscreenContainer';
import * as styles from './Gallery.module.scss';
import { YouTubeVideoPlayer } from './YouTubeVideoPlayer';

export interface IGalleryItem {
  thumbnail: string,
  image?: string,
  youTubeVideo?: {
    cover: string,
    videoId: string,
  }
}

export const Gallery = (props: { items: IGalleryItem[] }) => {
  const { items } = props;

  const [index, setIndex] = useState(0);
  const [mouseDownIndex, setMouseDownIndex] = useState<undefined | number>(undefined);
  const [zoomedIndex, setZoomedIndex] = useState<undefined | number>(undefined);

  const goTo = useCallback((value: number) => {
    setIndex(value);
  }, []);
  const previous = useCallback(() => {
    setIndex(Math.max(0, index - 1));
  }, [index]);
  const next = useCallback(() => {
    setIndex(Math.min(items.length - 1, index + 1));
  }, [index, items]);

  return (
    <div className={styles.gallery}>
      <div className={styles.list}>
        <div className={styles.swipeableViewsWrapper}>
          <SwipeableViews
            hysteresis={0.3}
            threshold={1.5}
            enableMouseEvents={true}
            index={index}
            onChangeIndex={goTo}
            onSwitching={() => {
              setMouseDownIndex(undefined);
            }}
          >
            {
              items.map((item, itemIndex) => {
                return (
                  <div
                    key={item.thumbnail}
                    className={styles.slide}
                    onMouseDown={() => {
                      setMouseDownIndex(itemIndex);
                    }}
                    onMouseUp={() => {
                      if (mouseDownIndex === itemIndex) {
                        if (mouseDownIndex == index) {
                          setZoomedIndex(itemIndex);
                        }
                        else {
                          setIndex(mouseDownIndex);
                        }
                      }
                      setMouseDownIndex(undefined);
                    }}
                  >
                    <div className={styles.slideInner}>
                      <img src={item.thumbnail} />
                      {
                        item.youTubeVideo !== undefined &&
                        <div
                          className={
                            classnames(
                              styles.slidePlayIcon,
                              { [styles.slidePlayIconActive]: index === itemIndex }
                            )
                          }
                        />
                      }
                    </div>
                  </div>
                );
              })
            }
          </SwipeableViews>
        </div>
        <button className={classnames(styles.navButtonLeft, { [styles.disabled]: index === 0 })} onClick={previous} />
        <button className={classnames(styles.navButtonRight, { [styles.disabled]: index >= items.length - 1 })} onClick={next} />
      </div>
      <div className={styles.dots}>
        {
          items.map((item, itemIndex) => {
            return (
              <div
                key={item.thumbnail}
                className={classnames(styles.dot, { [styles.dotActive]: index === itemIndex })}
                onClick={() => goTo(itemIndex)}
              />
            );
          })
        }
      </div>
      {
        zoomedIndex !== undefined &&
        <FullscreenGallery
          items={items}
          initialIndex={zoomedIndex}
          onClose={() => setZoomedIndex(undefined)}
        />
      }
    </div>
  );
}

const FullscreenGallery = (props: { items: IGalleryItem[], initialIndex?: number, onClose: () => void }) => {
  const { items, initialIndex, onClose } = props;

  const [index, setIndex] = useState(initialIndex ?? 0);

  useEffect(() => {
    const onKey = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          previous();
          break;
        case 'ArrowRight':
          next();
          break;
      }
    };

    window.addEventListener('keydown', onKey);

    return () => window.removeEventListener('keydown', onKey);
  });

  const goTo = useCallback((value: number) => {
    setIndex(value);
  }, []);
  const previous = useCallback(() => {
    setIndex(Math.max(0, index - 1));
  }, [index]);
  const next = useCallback(() => {
    setIndex(Math.min(items.length - 1, index + 1));
  }, [index, items]);

  return (
    <FullscreenContainer
      className={styles.full}
      onClose={onClose}
      bottom={(
        <div className={styles.dots}>
          {
            items.map((item, itemIndex) => {
              return (
                <div
                  key={item.thumbnail}
                  className={classnames(styles.dot, { [styles.dotActive]: index === itemIndex })}
                  onClick={(e) => {
                    e.stopPropagation();
                    goTo(itemIndex);
                  }}
                />
              );
            })
          }
        </div>
      )}
    >
      {(opened) => (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <SwipeableViews
            hysteresis={0.3}
            threshold={1.5}
            enableMouseEvents={true}
            index={index}
            onChangeIndex={goTo}
            onTransitionEnd={() => setIndex(index)}
          >
            {
              items.map((item, itemIndex) => {
                return (
                  <div
                    key={item.image || item.youTubeVideo.videoId}
                    className={styles.slide}
                  >
                    <div className={styles.slideInner}>
                      {
                        item.image &&
                        <img src={item.image} />
                      }
                      {
                        item.youTubeVideo && (
                          <img src={item.youTubeVideo.cover} style={{ objectFit: 'cover' }} />
                        )
                      }
                      {
                        opened && item.youTubeVideo && index === itemIndex && (
                          <div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}>
                            <YouTubeVideoPlayer
                              videoId={item.youTubeVideo.videoId}
                              width='100%'
                              height='100%'
                            />
                          </div>
                        )
                      }
                    </div>
                  </div>
                );
              })
            }
          </SwipeableViews>
        </div>
      )}
    </FullscreenContainer>
  );
}